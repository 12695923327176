import React from "react"
import { StoryGroup } from "components"

const eBooks = [
  {
    title: "JS ile Fonksiyonel Programlama",
    url:
      "https://learnreactui.dev/contents/JS%20ile%20Fonksiyonel%20Programlama",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2F0fbcea3e-f251-4bdf-80d7-d78f86b0a1fe%2Fcovers%2FJSFP_Cover2.png",
  },
]

export const LinkMap = {
  "Functional Programming": "fb03462f34c6",
  "Eric Elliott": "fb03462f34c6",
  "Composing Software Book": "fb03462f34c6",

  "Ada Lovelace": "260cdc6998df",
  "Hermann Hollerith": "260cdc6998df",
  "Alan Turing": "260cdc6998df",
  "Alonzo Chruch": "260cdc6998df",
  "David Hilbert": "260cdc6998df",
  "Wilhelm Ackermann": "260cdc6998df",

  "John McCarthy": "1c5f79f40c68",
  "Alan Kay": "1c5f79f40c68",

  "Imperative vs Declerative": "242e3dce26e",
  Procedural: "242e3dce26e",
  Structured: "242e3dce26e",
  "Object-Oriented": "242e3dce26e",

  "First Class Function": "7ad4d5318af7",
  "Pure Function": "7ad4d5318af7",
  "Shared State": "7ad4d5318af7",
  "Immutable Data": "7ad4d5318af7",
  "Side Effect": "7ad4d5318af7",
  "Higher Order Function": "7ad4d5318af7",

  "Turing Machine": "260cdc6998df",
  "Lambda Expression": "260cdc6998df",

  "Lambda Calculus": "1c5f79f40c68",
  Lisp: "1c5f79f40c68",
  AutoCad: "1c5f79f40c68",
  AutoLisp: "1c5f79f40c68",
  Racket: "1c5f79f40c68",
  Clojure: "1c5f79f40c68",
  Smalltalk: "1c5f79f40c68",
  "multi-paradigm": "1c5f79f40c68",

  Scala: "1c5f79f40c68",
  Rust: "1c5f79f40c68",
  Elixir: "1c5f79f40c68",
  Swift: "1c5f79f40c68",
  FSharp: "1c5f79f40c68",

  "Pure Functions": "24ab659cdc5",
  Concurrency: "24ab659cdc5",
  Mapping: "24ab659cdc5",
  Procedures: "24ab659cdc5",
  "I/O": "24ab659cdc5",

  "Side Effect 2": "24ab659cdc5",
  "Referential Transparency": "24ab659cdc5",
  "Shared State 2": "24ab659cdc5",
  Immutable: "24ab659cdc5",

  "Higher Order Function 2": "e85e36a00ec8",

  Currying: "bb8a82c53632",

  "Function Accepting Functions": "9bb17052a22b",

  "Partial Applications": "9bb17052a22b",
  Partial: "9bb17052a22b",
  "Partial Right": "9bb17052a22b",
  "Point Free Style": "9bb17052a22b",

  Abstraction: "15d4b8acfe8f",
  Composition: "15d4b8acfe8f",
  Simple: "15d4b8acfe8f",
  Concise: "15d4b8acfe8f",
  Reusable: "15d4b8acfe8f",
  Independent: "15d4b8acfe8f",
  Decomposable: "15d4b8acfe8f",
  Recomposable: "15d4b8acfe8f",

  "Data Types": "fc03a8f3b624",
  "Abstract Data Types": "fc03a8f3b624",

  Functor: "66e076974fcb",
  "Array Functor": "66e076974fcb",
  "Function Functor": "66e076974fcb",
  "String Functor": "66e076974fcb",
  "Identity Law": "66e076974fcb",
  "Composition Law": "66e076974fcb",

  "Type Functor": "66e076974fcb",
  "MayBe Functor": "66e076974fcb",
  "Either Functor": "66e076974fcb",

  "Partial Application": "b66034b3b889",
  partial: "b66034b3b889",
  partialRight: "b66034b3b889",

  "Point Free Style2": "cdc24ef309fe",

  Monad: "ea93fe8d7698",
  "Monad-Promise": "ea93fe8d7698",
  "List Monad": "ea93fe8d7698",
  "I/O Monad": "ea93fe8d7698",

  "Lambda, Calculus, TM": "56fb1b5540a4",
  "Computation Theory": "56fb1b5540a4",
  "Alan Kay2": "56fb1b5540a4",
  "Ivan Sutherland": "56fb1b5540a4",
  "Dynamic Delegation": "56fb1b5540a4",
  "Inheritance Concepts": "56fb1b5540a4",

  "Message Passing": "6a16a88b37eb",
  Encapsulation: "6a16a88b37eb",
  "Dynamic Binding": "6a16a88b37eb",

  "Avoiding Shared Mutable State": "9e8f1d424153",
  Decoupling: "9e8f1d424153",
  "Adaptability and Resilience to Changes": "9e8f1d424153",

  "Math,Cebir": "64f9db840442",

  Haskell: "ef5b0e616df8",
  "Static Type": "ef5b0e616df8",
  Polymorphism: "ef5b0e616df8",
  Inheritance: "ef5b0e616df8",

  encapsulation: "2f2645b57758",
  "message-passing": "2f2645b57758",
  "behavior sharing": "2f2645b57758",
  "subclass polymorphism": "2f2645b57758",

  "(MOT) Monitoring-Oriented Programming": "d11ef5c5514",

  "Object Inheritance": "2f287257376a",
  "Object Composition": "2f287257376a",

  "Class Inheritance": "83988c278e62",
  "Functional Inheritance": "83988c278e62",
  "Concatenative inheritance": "83988c278e62",
  "Prototype delegation": "83988c278e62",

  "Inheritance Over Composition": "4d88c6e9fafe",
  Association: "180b1c515156",
  Aggregation: "180b1c515156",
  Composition2: "180b1c515156",
  Concatenation: "180b1c515156",
  Delegation: "180b1c515156",

  "Factory Functions": "640ec245018c",
  "Mixin Composition": "3a2820a271b1",

  Class: "97f4b3d26120",
  constructor: "97f4b3d26120",
  new: "97f4b3d26120",
  this: "97f4b3d26120",
  extends: "97f4b3d26120",

  composable: "93e02f4a4e54",
  "data-types": "93e02f4a4e54",

  lenses: "8dd3661404a3",
  "view,set,over˝": "8dd3661404a3",

  Transducers: "7b79b0bbe280",
  "Elements of JavaScript Style": "7b79b0bbe280",
}

const stories = [
  {
    title: "JS ile Fonksiyonel Programlama",
    postId: "fb03462f34c6",
  },
  {
    title: "Imperative/Declarative Programlama",
    postId: "242e3dce26e",
  },
  {
    title: "Fonksiyonel Programlama Nedir?",
    postId: "7ad4d5318af7",
  },
  {
    title: "Fonksiyonel Programlamanın Başlangıcı",
    postId: "260cdc6998df",
  },
  {
    title: "Fonksiyonel Programlamanın Gelişimi",
    postId: "1c5f79f40c68",
  },
  {
    title: "Saf (Pure) Fonksiyon Nedir?",
    postId: "24ab659cdc5",
  },
  {
    title: "High Order Functions",
    postId: "e85e36a00ec8",
  },
  {
    title: "Currying",
    postId: "bb8a82c53632",
  },
  {
    title: "Function Accepting Functions",
    postId: "9bb17052a22b",
  },
  {
    title: "Partial Applications",
    postId: "b66034b3b889",
  },
  {
    title: "Point Free Style",
    postId: "cdc24ef309fe",
  },
  {
    title: "Soyutlama ve Kapsama",
    postId: "15d4b8acfe8f",
  },
  {
    title: "Soyut Veri Tipleri",
    postId: "fc03a8f3b624",
  },
  {
    title: "Functor & Categories",
    postId: "66e076974fcb",
  },
  {
    title: "Monad",
    postId: "ea93fe8d7698",
  },
  {
    title: "OOP Unutulmuş Tarihi-1",
    postId: "56fb1b5540a4",
  },
  {
    title: "OOP Unutulmuş Tarihi-2",
    postId: "6a16a88b37eb",
  },
  {
    title: "OOP Unutulmuş Tarihi-3",
    postId: "9e8f1d424153",
  },
  {
    title: "OOP Unutulmuş Tarihi-4",
    postId: "64f9db840442",
  },
  {
    title: "OOP Unutulmuş Tarihi-5",
    postId: "ef5b0e616df8",
  },
  {
    title: "OOP Unutulmuş Tarihi-6",
    postId: "2f2645b57758",
  },
  {
    title: "OOP Unutulmuş Tarihi-7",
    postId: "d11ef5c5514",
  },
  {
    title: "Object Composition-1",
    postId: "2f287257376a",
  },
  {
    title: "Object Composition-2",
    postId: "83988c278e62",
  },
  {
    title: "Object Composition-3",
    postId: "4d88c6e9fafe",
  },
  {
    title: "Object Composition-4",
    postId: "180b1c515156",
  },
  {
    title: "Factory Functions",
    postId: "640ec245018c",
  },
  {
    title: "Functional Mixins",
    postId: "3a2820a271b1",
  },
  {
    title: "Sınıflar ile Kapsama(Composition) Neden Zordur?",
    postId: "97f4b3d26120",
  },
  {
    title: "Composable Custom Datatypes",
    postId: "93e02f4a4e54",
  },
  {
    title: "Lenses",
    postId: "8dd3661404a3",
  },
  {
    title: "Transducers",
    postId: "7b79b0bbe280",
  },
  {
    title: "Elements of JavaScript Style",
    postId: "7c7867a3370e",
  },
  // {
  // 	title: "Mocking is a Code Smell",
  // 	postId: "",
  // 	enabled: false,
  // },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "JS Fonksiyonel Programlama",
  path: "javascript-ile-fonksiyonel-programlama",
}

const JSFunctionalProgrammingPage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
      eBooks={eBooks}
    />
  )
}

export default JSFunctionalProgrammingPage
